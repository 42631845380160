import { loadState } from '../utils/helper';

const themeDefaultState =
  loadState('font-size') === 10 ? loadState('font-size') : 0;

const counterReducer = (state = themeDefaultState, action) => {
  switch (action.type) {
    case 'DECREMENT':
      return 0;
    case 'INCREMENT':
      return 10;
    default:
      return state;
  }
};
export default counterReducer;
