import counterReducer from './counter';
import loggedReducer from './isLogged';

import authReducer from './authReducer';
import doctorReducer from './doctorReducer';
import patientReducer from './patientReducer';
import dietitianReducer from './dietitianReducer';
import moduleReducer from './moduleReducer';
import fontReducer from './fontReducer';

import { combineReducers } from 'redux';

const allReducers = combineReducers({
  counter: counterReducer,
  isLogged: loggedReducer,
  authR: authReducer,
  patientR: patientReducer,
  dietitianR: dietitianReducer,
  doctorR: doctorReducer,
  moduleR: moduleReducer,
  fontR: fontReducer,
});

export default allReducers;
