import axios from 'axios';
import {
  UPDATE_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAIL,
  GET_PATIENT_SUCCESS,
  GET_PATIENT_FAIL,
  GET_PATIENTS_SUCCESS,
  GET_PATIENTS_FAIL,
  SET_PATIENT_LOADING,
  CLEAR_PATIENT_ERROR,
  GET_PATIENT_TRACKER_SUCCESS,
  GET_PATIENT_TRACKER_FAIL,
  BOOK_APPOINTMENT_SUCCESS,
  BOOK_APPOINTMENT_FAIL,
  UPDATE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_FAIL,
  GET_APPOINTMENT_SUCCESS,
  GET_APPOINTMENT_FAIL,
} from '../reducers/types';
import { returnError, tokenConfig } from './actionHelperFunc';

export const getPatientByNumber = (number) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_PATIENT_ERROR });
    dispatch({ type: SET_PATIENT_LOADING });

    const { data } = await axios.post(
      `/api/patient/details`,
      { phoneNumber: number },
      tokenConfig(getState)
    );

    dispatch({
      type: GET_PATIENT_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({ type: GET_PATIENT_FAIL, payload: returnError(error) });
  }
};

export const getPatientById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_PATIENT_ERROR });
    dispatch({ type: SET_PATIENT_LOADING });

    const { data } = await axios.post(
      `/api/patient/details/id`,
      { id: id },
      tokenConfig(getState)
    );

    dispatch({
      type: GET_PATIENT_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({ type: GET_PATIENT_FAIL, payload: returnError(error) });
  }
};

export const getAllPatients = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_PATIENT_ERROR });
    dispatch({ type: SET_PATIENT_LOADING });

    const { data } = await axios.get(
      `/api/patient/patients`,
      tokenConfig(getState)
    );

    dispatch({
      type: GET_PATIENTS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({ type: GET_PATIENTS_FAIL, payload: returnError(error) });
  }
};

export const getAllPatientsByDoctorId = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_PATIENT_ERROR });
    dispatch({ type: SET_PATIENT_LOADING });

    const { data } = await axios.post(
      `/api/doctor/patientsSummary`,
      { doctorId: id },
      tokenConfig(getState)
    );

    dispatch({
      type: GET_PATIENTS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({ type: GET_PATIENTS_FAIL, payload: returnError(error) });
  }
};

export const updatePatient = (reqBody) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_PATIENT_ERROR });
    dispatch({ type: SET_PATIENT_LOADING });

    const { data } = await axios.post(
      `/api/patient/updatePatient`,
      reqBody,
      tokenConfig(getState)
    );

    // console.log(data);

    dispatch({
      type: UPDATE_PATIENT_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: UPDATE_PATIENT_FAIL, payload: returnError(error) });
  }
};

export const getPatientTracker = (reqBody) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_PATIENT_ERROR });
    dispatch({ type: SET_PATIENT_LOADING });

    const { data } = await axios.post(
      `/api/patient/getPatientTracker`,
      reqBody,
      tokenConfig(getState)
    );

    dispatch({
      type: GET_PATIENT_TRACKER_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({ type: GET_PATIENT_TRACKER_FAIL, payload: returnError(error) });
  }
};

export const bookAppointment = (reqBody) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_PATIENT_ERROR });
    dispatch({ type: SET_PATIENT_LOADING });

    const { data } = await axios.post(
      `/api/patient/setAppointment/v2`,
      reqBody,
      tokenConfig(getState)
    );

    dispatch({
      type: BOOK_APPOINTMENT_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({ type: BOOK_APPOINTMENT_FAIL, payload: returnError(error) });
  }
};

export const getAppointmentById = (reqBody) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_PATIENT_ERROR });
    dispatch({ type: SET_PATIENT_LOADING });

    const { data } = await axios.post(
      `/api/patient/getAppointment`,
      reqBody,
      tokenConfig(getState)
    );

    dispatch({
      type: GET_APPOINTMENT_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({ type: GET_APPOINTMENT_FAIL, payload: returnError(error) });
  }
};

export const getAppointmentByPatient =
  (reqBody) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLEAR_PATIENT_ERROR });
      dispatch({ type: SET_PATIENT_LOADING });

      const { data } = await axios.post(
        `/api/patient/getAppointmentByPatient`,
        reqBody,
        tokenConfig(getState)
      );

      dispatch({
        type: GET_APPOINTMENT_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({ type: GET_APPOINTMENT_FAIL, payload: returnError(error) });
    }
  };

export const updateAppointment = (reqBody) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLEAR_PATIENT_ERROR });
    dispatch({ type: SET_PATIENT_LOADING });

    const { data } = await axios.post(
      `/api/patient/updateAppointment`,
      reqBody,
      tokenConfig(getState)
    );

    dispatch({
      type: UPDATE_APPOINTMENT_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({ type: UPDATE_APPOINTMENT_FAIL, payload: returnError(error) });
  }
};
