import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { supportingContentData } from './utils/supportingContent';
const resources = {
  en: {
    translation: {
      ...supportingContentData.en,
      virtualOnly: 'Virtual Only',
      notEligible: 'You are not eligible',
      Skip: 'Skip',
      paragraph: 'Browse by Modules',
      LetsGo: "Let's Go",
      reflection: 'Reflection',
      Action: 'Action',
      HOME: 'HOME',
      CONSULTATION: 'CONSULTATION',
      RECORDS: 'RECORDS',
      REFER: 'REFER FRIEND',
      HELP: 'HELP',
      LANGUAGE: 'Language',
      FONT: 'FONT SIZE:',
      LOG: 'LOG OUT',
      Records: 'Records',
      select: 'Select which records you would like to access',
      User_Profile: 'User Profile',
      Check_personal: 'Check your personal information here',
      View_Records: 'View Records',
      antrhop: 'Current Anthrop Details',
      check_antrhop: 'Check your anthrop details here',
      Progress: 'Progress',
      Check_Progress: 'Check your progress here',
      Medical_Profile: 'Medical Profile',
      Check_medical: 'Check your medical information here',
      Save: 'Save',
      Submit: 'Submit',
      Phone_Number: 'Phone Number',
      Email_Address: 'Email Address',
      Gender: 'Gender',
      Male: 'Male',
      Female: 'Female',
      Date_of_Birth: 'Date of Birth',
      First_Name: 'First Name',
      Last_Name: 'Last Name',
      Height: 'Height',
      Weight: 'Weight',
      Waist_Circumference: 'Waist Circumference',
      Click_Milestone:
        'Click on the Milestone & Module to view detailed report.',
      Important_Milestone: 'Important Milestone',
      Completion_Date: 'Completion Date: ',
      Click_on_the: 'Click on the',
      button_to_see_Doctor: 'button to see Advocate’s Note',
      Diagnosis_Year: 'Diagnosis Year',
      updated_by: 'Updated by',
      Edit: 'Edit',
      Appointment_History: 'Appointment History',
      No_appointment_booked_yet: 'No appointment booked yet',
      Book_Appointment: 'Book Appointment',
      About: 'About',
      Qualifications: 'Qualifications:',
      Appointment: 'Appointment',
      Select_Date: 'Select Date',
      Select_Available_Time_Slot: 'Select Available Time Slot',
      Unavailable_Time_Slot: 'There is no available time slot to book.',
      next: 'next',
      Next: 'Next',
      Selected_Slot: 'Selected Slot',
      Change_Date: 'Change Date',
      Confirm: 'Confirm',
      Call_Now: 'Call Now',
      complete_video_popup:
        'Thank you. Kindly proceed to watch our video on how you can manage Your Diabetes Better in 12 Weeks.',

      // Main Landing Page
      Who_Are_You: 'Who are you?',
      Please_Kindly_Select_The_Rel: 'Please do kindly select the relevant',
      Website_To_Proceed: 'website to proceed',
      Im_A: "I'm a",
      Pt_Intro_Page_Slide_1: 'A lifestyle change journey to a healthier you.',
      // Patient intro Page
      Pt_Intro_Page_Slide_3:
        'Designed to help make you feel more energetic in your day-to-day life.',
      Pt_Intro_Page_Slide_2:
        'Improve your eating habits, increase your physical activity & take charge of your blood sugar.',
      Pt_Intro_Page_Slide_4:
        'Make small impactful changes to your lifestyle to live well with diabetes.',

      // Patient Register Page
      Lets_Get_Started: "Let's get started by keying in",
      Your_Phone_Number: 'your phone number!',
      Enter_Phone_Number: 'Enter phone number',
      Only_Available_In_Malaysia: 'Only available for Malaysia mobile number',
      Send_OTP: 'Send OTP',

      // Patient OTP Page
      Enter_6_Digit_Code: 'Enter the 6-digit OTP code',
      Sent_To_Your_Phone_Num: 'sent to your phone number',
      OTP_Number: 'OTP Number',
      Resend_OTP_Code: 'Resend OTP code',
      consentNotice: 'consent notice',
      // Patient OTP Modal Disclaimer n Consent Modal
      By_Using_The_Web_Based_App: 'By using the web based app.',
      You_Agree_For_Practices: 'You agree for the practices described in',
      This_dalam: 'this',
      Privacy_Notice: `Abbott's privacy`,
      And_Agree_To: 'and agree to',
      Abott_Privacy_Policy: "Abbott's Privacy Policy",
      I_Have_Read: 'I have read and understood the terms of this',
      Dan_And: 'and',
      And_Consent_To_Process_Data:
        'and consent to the processing of my Personal Data as described.',
      I_Would_Also_Like_To_Receive_Comm:
        'I would also like to receive communication from Abbott by phone / email / SMS / WhatsApp regarding Abbott products and services, or for research purposes and DLP activities.',
      Proceed: 'Proceed',
      started: "Let's get started",
      In_this_module: 'In this module, you will need to complete:',
      // Patient First Time Reg
      First_Time_Registration: 'First Time Registration',
      Fill_In_Your_Reg_Details: 'Fill in your registration details below',
      // Key Doctor Page
      Your_Doctor_Info: "Your Advocate's Information",
      Doctor_Name: "Advocate's Name",
      If_Above_Info_Correct: 'If the above information is correct,',
      Enter_Correct_Doctor_Code_To_Confirm:
        "enter your DLP advocate's code to confirm",
      // Personal Details
      homeDoctorWord: 'DLP Advocate',
      Your_Personal_Details: 'Your Personal Details',
      // Medical Info
      Your_Medical_Info: 'Your Medical Information',
      Part_1: 'PART 1',
      Part_2: 'PART 2',
      Your_Measurements: 'Your Measurements',
      Your_Health_Related_Info: 'Your Health Related Information',
      confirmAppointment: 'Confirm Appointment',
      Usual_Weight: 'Usual Weight',
      Current_Weight: 'Current Weight',
      Date_Taken: 'Date Taken',
      Have_You_Noticed_Any_Weight_Changes:
        'Have you noticed any weight changes in the last 5 years?',
      Increased: 'Increased',
      Decreased: 'Decreased',
      No_Sig_CHange: 'No Significant Change',
      My_Weight_Fluctuate: 'My weight has been fluctuating',
      Year_Of_Type_2: 'Year of Type 2 Diabetes Diagnosis',
      keepLogin: 'Keep me log in',
      // Diabetes Checkbox
      Diabetes_Related_Complications: 'Diabetes related complications',
      Heart_Disease: 'Heart disease/cardiovascular disease',
      Kidney_Disease: 'Kidney disease',
      High_Blood_Pressure: 'High blood pressure',
      Stroke: 'Stroke',
      Foot_Comp: 'Foot complications',
      Eye_Comp: 'Eye complications',
      Others: 'Others',
      // Fields Med Info
      BP: 'Blood Pressure',
      Heart_Rate: 'Heart Rate',
      // Optional
      Optional: 'Optional',
      mandatory: 'Mandatory',
      FBG: 'Fasting Blood Glucose',
      Post_Prandial: 'Post-Prandial blood glucose',
      RBG: 'Random Blood Glucose',
      Total_Cholesterol: 'Total Cholesterol',
      Triglycerides: 'Triglycerides',
      Chol_HDL_Ratio: 'Cholesterol/HDL Ratio',
      // Upload File Med Info
      OR: 'OR',
      Upload_Lab_Rep_Here: 'Upload my Laboratory Report here',
      File_Uploaded_Success: 'File Uploaded Successfully',
      Upload_File: 'Upload File',
      // Add Family Med Info
      Close_Fam_Info: 'Close Family Information',
      You_Can_Add_3_Fam: 'You can only add up to 3 Family Members',
      Full_Name: 'Full Name',
      Mobile_Num: 'Mobile Number',
      Relationship: 'You are his/her...',
      Add_Fam_Members: 'Add family members',
      By_Check_U_Agree_Fam_Can_See:
        'You agree that your family members will be able to see your medical report.',
      // Modal Success Register
      Thank_You_Register: 'Thank you for registering!',
      Welcome_To_DLP: 'Welcome to the Diabetes Lifestyle Programme',
      Lets_Begin_Journey: "Let's Begin The Journey",

      // FTJ Page
      Add_To_Home_Screen_Tut: 'Add To Home Screen Tutorial',
      Add_Your_Programme_to_Home: 'Add your programme to homescreen',
      Follow_These_Steps_Memory_Free:
        'Follow these steps to get hassle-free access to the DLP App without sacrificing your memory space.',
      You_Can_Also_Receive_Real_Time:
        'You can also receive real time messages from us so that you are always first to be updated.',
      Select_Your_Phone_Type: 'Select Your Phone Type',
      Tutorial: 'Tutorial',
      Watch_Out_For_Message:
        'Watch out for your message. Your key to the first module will be arriving soon!',
      complete_watch_video:
        "You've completed the task for this week, 1 more to go!",
      rewatch_video: 'Thank you for watching!',
      welcome: 'Welcome...',
      welcome2: 'Welcome!',
      thank_you:
        'Thank you for registering. Welcome to the Diabetes Lifestyle Programme',
      incomplete: 'You have not finished module ',
      incomplete_module_1: 'You havent finished module 1 action!',
      continue_module_1_button: 'Continue Module 1 action',
      refer_friend: 'Refer Friend',
      invite_close_friend: 'Invite your close friend',
      click_to_invite_friend: 'Click the share button below to refer friend',
      Wait_for_them: 'Wait for them to click the link',
      complete_share: 'Complete',
      now_your_close_friend: 'Now your close friend can also refer you.',
      copy: 'Copy',
      share: 'Share',
      or: 'OR',
      COMPLETED: 'COMPLETED',
      My_Progress: 'My Progress',
      see_your_completion: 'View your module completion status here',
      go_to_home: 'Go to Home',
      complete_all:
        "You've completed all the tasks for the week. Well done in trying to be healthy!",
      Continue: 'Continue',
      ContinueM: 'Continue',
      Back: 'Back',
      ModuleBack: 'Back',
      disclaimer: '"Disclaimer & Consent"',
      Ok: 'Ok',
      family: 'Family',
      MaybeLater: 'Maybe Later',
      Okay: 'Okay',
      Diabetes: 'Diabetes',
      Patient: 'Patient',
      Doctor: 'Advocate',
      Dietitian: 'Dietitian',
      swipeToContinue: 'Swipe to Continue',
      techEnquiry: 'Technical enquiries',
      dlpProgramme: 'Diabetes Lifestyle Programme',
      Wonderful: 'Wonderful',
      Video: 'Video',
      NoVideo: 'No Video',
      NoReflection: 'No Reflection',
      foodFacts: 'Food Facts',
      Add: 'Add',
      Cancel: 'Cancel',
      clickInfo: 'Click on the family members name',
      clickInfo2: 'if you want to edit their information',
      Parents: 'Parents',
      Child: 'Child',
      Husband: 'Husband',
      Wife: 'Wife',
      Cousin: 'Cousin',
      Grandparents: 'Grandparents',
      answered: 'You have answered this module.',
      completeT1: 'Congratulation on Completing',
      completeT2: 'the Module 4!',
      completeT3: 'Time to book your first',
      completeT4: 'appointment with our dietitian!',
      Yes: 'Yes',
      No: 'No',
      learn1: 'Learn the importance of a',
      learn2: 'healthy lifestyle for diabetes',
      Morning: 'Morning',
      Evening: 'Evening',
      targetValue: 'Target value',
      bloodGlucose: 'Fasting blood glucose',
      postGlucose: 'Post-Prandial blood glucose',
      measuredAfterMeal: 'measured at least 90 mins after meal ',
      veryHighRisk: 'very high risk',
      highRisk: 'high risk',
      moderateRisk: 'moderate risk',
      forMost: 'For most',
      tight: 'tight',
      inputError: 'Please select at least one diabetes related complications',
      fileUploadError: 'Your advocate already uploaded your lab report.',
      helpdesk: 'WhatsApp Helpdesk',
      unlockRewardsAwait: 'Unlock rewards upon completion of this module!',
      module9aWait: 'Please complete Module 1 to 8 to unlock your rewards.',
    },
  },

  my: {
    translation: {
      ...supportingContentData.bm,
      virtualOnly: 'Maya Sahaja',
      notEligible: 'Anda tidak layak',
      unlockRewardsAwait: 'Tuntut ganjaran setelah menyelesaikan modul ini!',
      module9aWait: 'Tuntut ganjaran setelah menyelesaikan modul 1 hingga 8.',
      helpdesk: 'Talian bantuan',
      mandatory: 'Wajib',
      fileUploadError: 'Pakar DLP anda telah memuat naik laporan makmal anda.',
      inputError:
        'Sila pilih sekurang-kurangnya satu komplikasi berkaitan diabetes',
      forMost: 'Untuk kebanyakan',
      tight: 'Ketat',
      veryHighRisk: 'sangat berisiko tinggi',
      highRisk: 'berisiko tinggi',
      moderateRisk: 'risiko sederhana',
      measuredAfterMeal:
        'bacaan diambil sekurang-kurangnya 90 min selepas makan',
      postGlucose: 'Glukosa darah selepas makan',
      bloodGlucose: 'Glukosa darah puasa',
      targetValue: 'Nilai sasaran',
      learn1: 'Ketahui kepentingan gaya',
      learn2: 'hidup sihat untuk diabetes',
      Yes: 'Ya',
      No: 'Tidak',
      completeT1: 'Tahniah kerana Melengkapkan',
      completeT2: 'Modul 4!',
      completeT3: 'Masa untuk menempah temu janji',
      completeT4: 'pertama anda dengan pakar diet kami!',
      answered: 'Anda telah menjawab modul ini.',
      Parents: 'Ibu Bapa',
      Child: 'Anak',
      Husband: 'Suami',
      Wife: 'Isteri',
      Cousin: 'Sepupu',
      Grandparents: 'Atuk Nenek',
      clickInfo: 'Klik pada nama ahli keluarga',
      clickInfo2: 'jika anda ingin mengedit maklumat mereka',
      Add: 'Tambah',
      Cancel: 'Batal',
      foodFacts: 'Fakta Makanan',
      NoReflection: 'Tiada Refleksi',
      Video: 'Video',
      NoVideo: 'Tiada Video',
      Wonderful: 'Hebatnya',
      dlpProgramme: 'Program Gaya Hidup Diabetes',
      techEnquiry: 'Pertanyaan Teknikal',
      swipeToContinue: 'Leret untuk Meneruskan',
      Diabetes: 'Diabetes',
      Patient: 'Pesakit',
      Doctor: 'Pakar DLP',
      Dietitian: 'Pakar Diet',
      Appointment: 'Temu Janji',
      Okay: 'Baik',
      family: 'Keluarga',
      MaybeLater: 'Mungkin Kemudian',
      Ok: 'Seterusnya',
      Skip: 'Langkau',
      Continue: 'Hantar',
      ContinueM: 'Seterusnya',
      Back: 'Kembali',
      ModuleBack: 'Kembali',
      paragraph: 'Semak imbas mengikut modul',
      LetsGo: 'Mula',
      reflection: 'Refleksi',
      Action: 'Tindakan',
      HOME: 'LAMAN UTAMA',
      Submit: 'Hantar',
      CONSULTATION: 'JANJI TEMU',
      RECORDS: 'REKOD',
      REFER: 'RUJUK RAKAN',
      HELP: 'BANTUAN',
      LANGUAGE: `Bahasa`,
      FONT: 'SAIZ FON:',
      LOG: 'LOG KELUAR',
      Records: 'Rekod',
      select: 'Pilih rekod yang anda ingin akses',
      User_Profile: 'Profil Pengguna',
      Check_personal: 'Semak maklumat peribadi anda di sini',
      View_Records: 'Lihat Rekod',
      antrhop: 'Butiran antrhop semasa',
      check_antrhop: 'Semak butiran antrhop anda di sini',
      Progress: 'Progres',
      Check_Progress: 'Semak progres anda di sini',
      Medical_Profile: 'Profil Perubatan',
      Check_medical: 'Semak maklumat perubatan anda di sini',
      Save: 'Simpan',
      Phone_Number: 'Nombor Telefon',
      Email_Address: 'Alamat Email',
      Gender: 'Jantina',
      Male: 'Lelaki',
      Female: 'Perempuan',
      Date_of_Birth: 'Tarikh Lahir',
      First_Name: 'Nama Pertama',
      Last_Name: 'Nama Akhir',
      Height: 'Tinggi',
      Weight: 'Berat',
      Waist_Circumference: 'Lilitan Pinggang',
      Click_Milestone:
        'Klik pada Pencapaian & Modul untuk melihat laporan terperinci.',
      Important_Milestone: 'Pencapaian Penting',
      Completion_Date: 'Tarikh Siap: ',
      Click_on_the: 'Klik pada',
      button_to_see_Doctor: 'butang untuk melihat komen Pakar DLP.',
      Diagnosis_Year: 'Tahun Diagnosis',
      updated_by: 'Dikemaskini pada',
      Edit: 'Kemaskini',
      Appointment_History: 'Senarai Temu Janji',
      No_appointment_booked_yet: 'Tiada temu janji lagi',
      Book_Appointment: 'Tetapkan Temu Janji',
      About: 'Tentang',
      Qualifications: 'Kelayakan:',

      Select_Date: 'Pilih Tarikh',
      Select_Available_Time_Slot: 'Pilih Slot Masa Tersedia',
      Unavailable_Time_Slot: 'Tiada slot masa yang tersedia untuk ditempah.',
      next: 'Seterusnya',
      Next: 'Seterusnya',
      Selected_Slot: 'Slot Yang Dipilih',
      Change_Date: 'Tukar Tarikh',
      Confirm: 'Sahkan',
      Call_Now: 'Hubungi Sekarang',
      complete_video_popup:
        'Terima kasih. Sila teruskan menonton video kami tentang bagaimana anda boleh "Mengurus Diabetes Anda dengan Lebih Baik dalam 12 Minggu".',
      complete_watch_video:
        'Anda telah menyelesaikan tugasan pada minggu ini, tinggal 1 tugasan lagi.',
      rewatch_video: 'Terima kasih kerana menonton!',
      welcome: 'Selamat datang...',
      welcome2: 'Selamat datang!',
      thank_you:
        'Terima Kasih kerana mendaftar. Selamat datang ke Program Gaya Hidup Diabetes',
      incomplete: 'Anda masih belum menyiapkan modul ',
      incomplete_module_1: 'Anda masih belum menyiapkan tindakan modul 1!',
      continue_module_1_button: 'Teruskan tindakan modul 1',
      refer_friend: 'Rujuk Rakan',
      invite_close_friend: 'Jemput kenalan rapat anda',
      click_to_invite_friend: 'Klik butang kongsi di bawah untuk merujuk rakan',
      Wait_for_them: 'Tunggu mereka klik pautan',
      complete_share: 'Siap',
      now_your_close_friend:
        'Sekarang rakan karib anda juga boleh merujuk anda.',
      copy: 'Salin',
      share: 'Kongsi',
      or: 'atau',
      My_Progress: 'Progres Saya',
      see_your_completion: 'Lihat status kelengkapan modul anda di sini',
      go_to_home: 'Pergi ke halaman utama',
      complete_all:
        'Anda telah menyelesaikan semua tugasan untuk minggu ini. Tahniah atas usaha anda untuk menjadi sihat!',

      // Main Landing Page
      Who_Are_You: 'Siapakah anda?',
      Please_Kindly_Select_The_Rel: 'Sila pilih',
      Website_To_Proceed: 'website yang berkaitan dengan anda',
      Im_A: 'Saya adalah',
      Pt_Intro_Page_Slide_1:
        'Perjalanan perubahan gaya hidup untuk membantu anda menjadi lebih sihat.',
      // Patient Intro
      Pt_Intro_Page_Slide_3:
        'Direka untuk membantu anda berasa lebih bertenaga dalam melakukan aktiviti seharian.',
      Pt_Intro_Page_Slide_2:
        'Membaiki tabiat pemakanan, meningkatkan aktiviti fizikal & mengawal gula darah anda.',
      homeDoctorWord: 'Pakar DLP',
      Pt_Intro_Page_Slide_4:
        'Lakukan perubahan kecil yang memberi impak pada gaya hidup anda agar anda boleh hidup sihat dengan diabetes.',
      disclaimer: '"Penafian & Persetujuan"',
      // Patient Register Page
      Lets_Get_Started: 'Mari mulakan dengan memasukkan',
      Your_Phone_Number: 'nombor telefon anda!',
      Enter_Phone_Number: 'Masukkan nombor telefon',
      Only_Available_In_Malaysia: 'Hanya untuk nombor mudah alih Malaysia',
      Send_OTP: 'Hantar OTP',

      // Patient OTP Page
      Enter_6_Digit_Code: 'Masukkan kod OTP 6 digit',
      Sent_To_Your_Phone_Num: 'yang dihantar kepada nombor telefon bimbit anda',
      OTP_Number: 'Nombor OTP',
      Resend_OTP_Code: 'Hantar Semula Kod OTP',
      consentNotice: 'notis persetujuan',
      // Patient OTP Modal Disclaimer n Consent Modal
      By_Using_The_Web_Based_App: 'Dengan menggunakan aplikasi berasaskan web,',
      You_Agree_For_Practices: 'anda bersutuju dengan amalan yang diterangkan',
      This_dalam: 'dalam',
      Privacy_Notice: "Abbott's privasi",
      And_Agree_To: 'ini dan bersetuju dengan',
      Abott_Privacy_Policy: 'Dasar Privasi Abbott',
      I_Have_Read: 'Saya telah membaca dan memahami syarat-syarat ini',
      Dan_And: 'dan',
      And_Consent_To_Process_Data:
        'dan persetujuan untuk pemprosesan Data Peribadi saya seperti yang diterangkan.',
      I_Would_Also_Like_To_Receive_Comm:
        'Saya juga ingin menerima komunikasi daripada Abott melalui telefon / e-mel / SMS / WhatsApp mengenai produk dan perkhidmatan Abott, atau untuk tujuan penyelidikan dan aktiviti DLP.',
      Proceed: 'Teruskan',
      Morning: 'Pagi',
      Evening: 'Petang',
      started: 'Mari kita mulakan',
      In_this_module: 'Di dalam modul ini anda perlu melengkapkan: ',
      confirmAppointment: 'Sahkan Temu Janji',
      // Patient First Time Reg
      First_Time_Registration: 'Pendaftaran Kali Pertama',
      Fill_In_Your_Reg_Details: 'Isikan butiran pendaftaran anda di bawah',
      // Key Doctor Page
      Your_Doctor_Info: 'Maklumat Pakar DLP Anda',
      Doctor_Name: 'Nama Pakar DLP',
      If_Above_Info_Correct: 'Jika maklumat di atas adalah betul,',
      Enter_Correct_Doctor_Code_To_Confirm:
        'masukkan kod pakar DLP anda untuk mengesahkan',
      // Personal Details
      Your_Personal_Details: 'Butiran Peribadi Anda',
      // Medical Info
      Your_Medical_Info: 'Maklumat Perubatan Anda',
      Part_1: 'BAHAGIAN 1',
      Part_2: 'BAHAGIAN 2',
      Your_Measurements: 'Ukuran Anda',
      Your_Health_Related_Info: 'Maklumat Berkaitan Kesihatan Anda',
      Usual_Weight: 'Berat Biasa',
      Current_Weight: 'Berat Semasa',
      Date_Taken: 'Tarikh Diambil',
      Have_You_Noticed_Any_Weight_Changes:
        'Adakah anda perasan apa-apa perubahan berat badan dalam tempoh 5 tahun yang lalu?',
      Increased: 'Meningkat',
      Decreased: 'Mengurang',
      No_Sig_CHange: 'Tiada Perubahan Ketara',
      My_Weight_Fluctuate: 'Berat Badan Tidak Stabil',
      Year_Of_Type_2: 'Tahun Diagnosis Diabetes Jenis 2',
      // Diabetes Checkbox
      Diabetes_Related_Complications: 'Komplikasi berkaitan diabetes',
      Heart_Disease: 'Penyakit Kardiovaskular/Jantung',
      Kidney_Disease: 'Penyakit Buah Pinggang(Nefropati)',
      High_Blood_Pressure: 'Tekanan Darah Tinggi',
      Stroke: 'Strok',
      Foot_Comp: 'Komplikasi Kaki',
      Eye_Comp: 'Komplikasi Mata',
      Others: 'Lain-lain',
      // Fields Med Info
      BP: 'Tekanan Darah',
      Heart_Rate: 'Kadar degupan jantung',
      // Optional
      Optional: 'Tambahan',
      FBG: 'Glukosa Darah Puasa',
      Post_Prandial: 'Glukosa darah post-Prandial',
      RBG: 'Glukosa Darah Rawak',
      Total_Cholesterol: 'Jumlah Kolesterol',
      Triglycerides: 'Trigliserida',
      Chol_HDL_Ratio: 'Nisbah Kolesterol/HDL',
      // Upload File Med Info
      OR: 'ATAU',
      COMPLETED: 'SELESAI',
      Upload_Lab_Rep_Here: 'Muat Naik Laporan Kesihatan Saya',
      File_Uploaded_Success: 'Fail berjaya dimuat naik',
      Upload_File: 'Muat Naik Fail',
      // Add Family Med Info
      Close_Fam_Info: 'Maklumat Keluarga Terdekat',
      You_Can_Add_3_Fam: 'Anda hanya boleh menambah sehingga 3 Ahli Keluarga',
      Full_Name: 'Nama Penuh',
      Mobile_Num: 'Nombor Telefon',
      Relationship: 'Hubungan dengan pesakit:',
      Add_Fam_Members: 'Tambah ahli keluarga',
      By_Check_U_Agree_Fam_Can_See:
        'Anda bersetuju bahawa ahli keluarga anda akan dapat melihat laporan perubatan anda.',
      // Modal Success Register
      Thank_You_Register: 'Terima Kasih kerana mendaftar!',
      Welcome_To_DLP: 'Selamat datang ke Program Gaya Hidup Diabetes',
      Lets_Begin_Journey: 'Mari Mulakan Perjalanan',
      keepLogin: 'Teruskan saya log masuk',
      // FTJ Page
      Add_To_Home_Screen_Tut: 'Tutorial penambahan aplikasi pada skrin utama',
      Add_Your_Programme_to_Home: 'Tambahkan program anda pada skrin utama',
      Follow_These_Steps_Memory_Free:
        'Ikuti langkah ini untuk mengakses aplikasi DLP dengan mudah tanpa menggunakan memori telefon bimbit anda.',
      You_Can_Also_Receive_Real_Time:
        'Anda juga boleh menerima mesej masa nyata daripada kami supaya anda menjadi yang pertama menerima maklumat terkini.',
      Select_Your_Phone_Type: 'Pilih Jenis Telefon Anda',
      Watch_Out_For_Message:
        'Perhatikan peti e-mel anda dan hidupkan notifikasi. Kunci modul pertama anda akan tiba tidak lama lagi!',
    },
  },
};

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('i18nextLng') || 'my',
    fallbackLng: 'my',
    keySeparator: '.',
    returnObjects: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
