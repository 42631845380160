// AUTH related types
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_FAIL = 'SEND_OTP_FAIL';

export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAIL = 'ADMIN_LOGIN_FAIL';
export const ADMIN_LOGOUT_SUCCESS = 'ADMIN_LOGOUT_SUCCESS';

export const LOGOUT_SUCCUSS = 'LOGOUT_SUCCUSS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const SET_LOADING = 'SET_LOADING';
export const CLEAR_ERROR = 'CLEAR_ERROR';

// PATIENT related types
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
export const UPDATE_PATIENT_FAIL = 'UPDATE_PATIENT_FAIL';

export const GET_PATIENT_SUCCESS = 'GET_PATIENT_SUCCESS';
export const GET_PATIENT_FAIL = 'GET_PATIENT_FAIL';

export const GET_PATIENT_TRACKER_SUCCESS = 'GET_PATIENT_TRACKER_SUCCESS';
export const GET_PATIENT_TRACKER_FAIL = 'GET_PATIENT_TRACKER_FAIL';

export const GET_PATIENTS_SUCCESS = 'GET_PATIENTS_SUCCESS';
export const GET_PATIENTS_FAIL = 'GET_PATIENTS_FAIL';

export const BOOK_APPOINTMENT_SUCCESS = 'BOOK_APPOINTMENT_SUCCESS';
export const BOOK_APPOINTMENT_FAIL = 'BOOK_APPOINTMENT_FAIL';

export const GET_APPOINTMENT_SUCCESS = 'GET_APPOINTMENT_SUCCESS';
export const GET_APPOINTMENT_FAIL = 'GET_APPOINTMENT_FAIL';

export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS';
export const UPDATE_APPOINTMENT_FAIL = 'UPDATE_APPOINTMENT_FAIL';

export const SET_PATIENT_LOADING = 'SET_PATIENT_LOADING';
export const CLEAR_PATIENT_ERROR = 'CLEAR_PATIENT_ERROR';

// DIETITIAN actions types
export const UPDATE_DIETITIAN_SUCCESS = 'UPDATE_DIETITIAN_SUCCESS';
export const UPDATE_DIETITIAN_FAIL = 'UPDATE_DIETITIAN_FAIL';

export const GET_DIETITIAN_SUCCESS = 'GET_DIETITIAN_SUCCESS';
export const GET_DIETITIAN_FAIL = 'GET_DIETITIAN_FAIL';

export const GET_DIETITIANS_SUCCESS = 'GET_DIETITIANS_SUCCESS';
export const GET_DIETITIANS_FAIL = 'GET_DIETITIANS_FAIL';

export const GET_DIETITIAN_APPOINTMENTS_SUCCESS =
  'GET_DIETITIANS_APPOINTMENT_SUCCESS';
export const GET_DIETITIAN_APPOINTMENTS_FAIL =
  'GET_DIETITIAN_APPOINTMENTS_FAIL';

export const GET_PATIENT_APPOINTMENTS_SUCCESS =
  'GET_PATIENT_APPOINTMENT_SUCCESS';
export const GET_PATIENT_APPOINTMENTS_FAIL = 'GET_PATIENT_APPOINTMENTS_FAIL';

export const GET_DIETITIAN_AVAILABLE_SUCCESS =
  'GET_DIETITIANS_AVAILABLE_SUCCESS';
export const GET_DIETITIANS_AVAILABLE_FAIL = 'GET_DIETITIANS_AVAILABLE_FAIL';

export const SET_DIETITIAN_LOADING = 'SET_DIETITIAN_LOADING';
export const CLEAR_DIETITIAN_ERROR = 'CLEAR_DIETITIAN_ERROR';

// DOCTOR actions types
export const UPDATE_DOCTOR_SUCCESS = 'UPDATE_DOCTOR_SUCCESS';
export const UPDATE_DOCTOR_FAIL = 'UPDATE_DOCTOR_FAIL';

export const GET_DOCTOR_SUCCESS = 'GET_DOCTOR_SUCCESS';
export const GET_DOCTOR_FAIL = 'GET_DOCTOR_FAIL';

export const GET_DOCTORS_SUCCESS = 'GET_DOCTORS_SUCCESS';
export const GET_DOCTORS_FAIL = 'GET_DOCTORS_FAIL';

export const SET_DOCTOR_LOADING = 'SET_DOCTOR_LOADING';
export const CLEAR_DOCTOR_ERROR = 'CLEAR_DOCTOR_ERROR';

export const GET_DOCTOR_COMMENTS_SUCCESS = 'GET_DOCTOR_COMMENTS_SUCCESS';
export const GET_DOCTOR_COMMENTS_FAIL = 'GET_DOCTOR_COMMENTS_FAIL';

// Service Worker actions types
export const SW_INIT = 'SW_INIT';
export const SW_UPDATE = 'SW_UPDATE';

// MODULE actions types
export const UPDATE_MODULE_SUCCESS = 'UPDATE_MODULE_SUCCESS';
export const UPDATE_MODULE_FAIL = 'UPDATE_MODULE_FAIL';

export const ADD_MODULE_ANSWER_SUCCESS = 'ADD_MODULE_ANSWER_SUCCESS';
export const ADD_MODULE_ANSWER_FAIL = 'ADD_MODULE_ANSWER_FAIL';

export const GET_MODULE_ANSWER_SUCCESS = 'GET_MODULE_ANSWER_SUCCESS';
export const GET_MODULE_ANSWER_FAIL = 'GET_MODULE_ANSWER_FAIL';

export const GET_MODULE_SUCCESS = 'GET_MODULE_SUCCESS';
export const GET_MODULE_FAIL = 'GET_MODULE_FAIL';

export const GET_MODULE_QUESTIONS_SUCCESS = 'GET_MODULE_QUESTIONS_SUCCESS';
export const GET_MODULE_QUESTIONS_FAIL = 'GET_MODULE_QUESTIONS_FAIL';

export const GET_DUAL_MODULE_QUESTIONS_SUCCESS =
  'GET_DUAL_MODULE_QUESTIONS_SUCCESS';
export const GET_DUAL_MODULE_QUESTIONS_FAIL = 'GET_DUAL_MODULE_QUESTIONS_FAIL';

export const GET_MODULE_RE_QS_SUCCESS = 'GET_MODULE_RE_QS_SUCCESS';
export const GET_MODULE_RE_QS_FAIL = 'GET_MODULE_RE_QS_FAIL';

export const GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS';
export const GET_MODULES_FAIL = 'GET_MODULES_FAIL';

export const SET_MODULE_LOADING = 'SET_MODULE_LOADING';
export const CLEAR_MODULE_ERROR = 'CLEAR_MODULE_ERROR';
