export const supportingContentData = {
  en: {
    welcome: 'Welcome!',
    hitMilestone: `Hit milestones to unlock rewards along your diabetes care journey with us!`,
    letStartToday: `Let's start today!`,
    proceed: 'Proceed',
    important: 'Important! Please Read',
    importantDescription: `This programme supports Type 2 Diabetes* patients by providing materials related to DLP learning modules in this web app when a certain milestone is reached. ​`,
    criteriaApplies:
      '*criteria applies & recruitment is up to advocate’s discretion',
    note: 'Note:',
    noteList: [
      'All supporting materials are provided with no charges imposed.​',
      'Each material will only be provided once & while stocks last.​',
      'Any changes in delivery address can be made under ‘Menu’ > ‘My Rewards’.',
      'You are not allowed to make changes or cancel order once your order is out for delivery.',
      `DLP will not be responsible for any damages on materials caused by delivery or users.​`,
    ],
    clickToRead: 'Click to read the',
    privacyNotice: 'Privacy Notice',
    and: 'and',
    policy: `Abbott's Privacy Policy`,
    forParticipation: 'for participating in DLP before proceeding',
    wishToReceive: 'Do you wish to receive supporting materials?​',
    youWillBeAsked:
      'You will be asked to confirm again at each milestone. If you change your mind, you may decide again at that point.',
    provideDeliveryDetails: 'Please provide your details for delivery',
    addressDetail: 'Address Detail',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    city: 'City',
    country: 'Country',
    state: 'State',
    zipCode: 'ZIP / postcode',
    cancel: 'Cancel',
    congratulationM3:
      'Congratulations on initiating Module 3! To support your journey fighting diabetes, we are giving you a set of Glucometer with strips!',
    wouldYouLikeToReceiveIt: 'Would you like to receive it?',
    wouldYouLikeToReceiveThem: 'Would you like to receive them?',
    deliveringToAddress:
      'We will be delivering to the address you have provided.',
    yourRequestHasBeenRecorded:
      'Your request has been recorded. The item will be delivered to your address below:',
    yourItemWillBeDeliveredIn: 'Your item will be delivered in',
    workingDays: 'working days.',
    editDeliveryAddress: 'Edit Delivery Address',
    weWillNotSendYou: 'We will not send you the item mentioned',
    congratulationOnCompletingM4: `Congratulations on completing Module 4! We have the healthy meal plans here to help you out. Find out from your dietitian on the meal plan that suits you most.`,
    clickTheLinkBelow: 'Click the link below to check out meal plans​',
    thisGiftAwaitsYou: 'This Gift Awaits You!',
    bookAnAppointmentToEarn: 'Book an appointment with our dietitian to earn',
    twoUnitsOfGlucerna400g: '2 units of Glucerna 400g (GTSN)',
    glucerna400g: 'Glucerna 400g x2',
    glucerna2: '2 Glucerna 400g',
    headsUpClaimYourMaterials:
      'Heads up! Claim your supporting materials in this module.',
    congratulationOnCompletingVirtualConsulation:
      'Congratulations on completing your first virtual dietitian consultation! You have been rewarded with',
    consultation: 'Consultation',
    likeToReceiveIt: 'Would you like to receive it?',
    willBeDeliveringToTheAddress:
      'We will be delivering to the address you have provided',
    youCanStartYourExercise:
      'You can now start your exercises with the help of this stretch band and sport towel!',
    congratulationOnCompletingM9: `Congratulations on completing Module 9!
  We want you to continue live well and be in control of your diabetes. Hereby rewarding you`,
    glucoseStrips: 'glucose strips.',
    myRewards: 'My Rewards',
    myRewardsLink: 'MY REWARDS',
    completed: 'Completed',
    trackDelivery: 'Track Delivery',
    cancelDelivery: 'Cancel Delivery',
    setOfGlucometerWithStrips: 'Set of Glucometer with strips',
    oneGlucometer: '1 Glucometer & 1 box test strips​',
    mealPlan: 'Mean Plan',
    mealPlanPath: 'PATh Meal Plan',
    sportTowel: '1 stretch band & 1 sports towel',
    glucernaWithStrips: '2 Glucerna 400g & 1 box test strips',
    download: 'Download',
    notCompleted: 'Not Completed',
    goToCourse: 'Go to Course',
    attendNow: 'Attend Now',
    doYouWantToCancel:
      'Do you want to cancel your order of supporting material(s)?',
    ifYouChangeYourMind:
      'If you change your mind & wish to redeem the material(s), kindly contact us via:',
    deliveryDetails: 'Delivery Details',
    deliveryStatus: 'Delivery Status',
    toReceive: 'To Receive',
    shippingInformation: 'Shipping Information',
    deliveryId: 'Delivery ID',
    item: 'Item',
    glucometerWithStrips: 'Glucometer with Strips',
    deliveryAddress: 'Delivery Address',
    trackYourOrder: 'Track Your Order',
    orderReceived: 'Order Received',
    weReceivedYourOrder: 'We Received your order on',
    orderProcessing: 'Order Processing',
    weArePreparingYourOrder: 'We are preparing your order',
    orderShipped: 'Order Shipped',
    trackingCode: 'Tracking Code',
    checkYourDeliveryStatus: 'Check your Delivery Status',
    here: 'here',
    myAddress: 'My Address',
    myAddressLink: 'MY ADDRESS',
    contact: 'Contact',
    address: 'Address',
    saveAddress: 'Save Address',
    module: 'Module',
    viewMealPlan: 'View Meal Plan',
    received: 'Received',
  },
  bm: {
    welcome: 'Selamat datang!',
    hitMilestone: `Capai sasaran dan mendapat ganjaran sepanjang perjalanan penjagaan diabetes anda bersama kami!`,
    letStartToday: `Mari mula hari ini!`,
    proceed: 'Teruskan',
    important: 'MAKLUMAN PENTING! SILA BACA​',
    importantDescription: `Program ini menyokong pesakit diabetes jenis 2* dengan menyediakan bahan sokongan berkaitan dengan modul pembelajaran DLP melalui aplikasi web setelah pencapaian tertentu dicapai.​​`,
    criteriaApplies:
      '*berdasarkan kriteria tertentu dan pengambilan bergantung kepada pertimbangan penyokong DLP',
    note: 'NOTA:',
    noteList: [
      'Semua bahan sokongan disediakan secara percuma​',
      'Setiap bahan sokongan akan diberikan sekali sahaja dan sementara masih ada.',
      'Sebarang pertukaran alamat penghantaran boleh dilakukan melalui ‘Menu’ > ‘Ganjaran Saya’.',
      `Anda tidak dibenarkan untuk membuat sebarang pertukaran atau pembatalan kepada pesanan anda apabila sudah dalam penghantaran.`,
      `DLP tidak bertanggungjawab atas sebarang kerosakan semasa penghantaran atau disebabkan oleh pengguna.`,
    ],
    clickToRead: 'Sila klik untuk membaca',
    privacyNotice: 'Notis Privasi',
    and: 'dan',
    policy: `Dasar Privasi Abbott`,
    forParticipation: 'bagi penglibatan dalam DLP sebelum meneruskan.',
    wishToReceive: 'Adakah anda setuju untuk menerima bahan sokongan?​​',
    youWillBeAsked:
      'Anda akan diminta mengesahkan di setiap pencapaian. Jika anda berubah fikiran, anda boleh menetapkan keputusan anda sekali lagi kemudian.​',
    provideDeliveryDetails:
      'Sila berikan maklumat anda untuk penghantaran barang.',
    addressDetail: 'Maklumat Penghantaran',
    addressLine1: 'Alamat Baris 1',
    addressLine2: 'Alamat Baris 2',
    city: 'Bandar',
    country: 'Negara',
    zipCode: 'Poskod',
    state: 'Negeri',
    cancel: 'Batalkan',
    congratulationM3:
      'Tahniah kerana telah menyelesaikan Modul 3! Untuk membantu dalam perjuangan anda menentang diabetes, anda diberikan satu set glukometer dengan strip ujian!',
    wouldYouLikeToReceiveIt: 'Adakah anda ingin menerimanya?',
    wouldYouLikeToReceiveThem: 'Adakah anda ingin menerima mereka?',
    deliveringToAddress:
      'Kami akan menghantar barang ke alamat yang anda berikan.',
    yourRequestHasBeenRecorded:
      'Permintaan anda telah direkodkan. Barang akan dihantar ke alamat anda seperti di bawah:',
    yourItemWillBeDeliveredIn: 'Barang anda akan dihantar dalam masa ',
    workingDays: 'hari bekerja.',
    editDeliveryAddress: 'Kemaskini Alamat Penghantaran',
    weWillNotSendYou: 'Kami tidak akan menghantar barang tersebut kepada anda.',
    congratulationOnCompletingM4: `Tahniah kerana telah memulakan Modul 4! Kami menyediakan pelan makanan sihat untuk membantu anda. Sila tanyakan pakar diet mengenai pelan makanan yang sesuai untuk anda.`,
    clickTheLinkBelow: 'Klik pautan di bawah untuk menyemak rancangan makan​​',
    thisGiftAwaitsYou: 'Hadiah ini menantikan anda!',
    bookAnAppointmentToEarn:
      'Tempah temu jaji dengan ahli pemakanan kami untuk memperoleh',
    twoUnitsOfGlucerna400g: '2 unit Glucerna 400g (GTSN)',
    glucerna400g: 'Glucerna 400g x2',
    glucerna2: '2 Glucerna 400g',
    headsUpClaimYourMaterials:
      'Perhatian! Tuntut bahan sokongan anda dalam modul ini.',
    congratulationOnCompletingVirtualConsulation:
      'Tahniah atas penyelesaian konsultasi pakar diet maya pertama anda! Anda telah diberikan ganjaran',
    consultation: 'Konsultasi',
    likeToReceiveIt: 'Adakah anda ingin menerimanya?',
    willBeDeliveringToTheAddress:
      'Kami akan menghantar barang ke alamat yang anda berikan.',
    youCanStartYourExercise:
      'Anda kini boleh memulakan senaman anda dengan bantuan stretch band dan tuala sukan ini!',
    congratulationOnCompletingM9: `Tahniah kerana menyelesaikan Modul 9! Kami ingin anda terus hidup sihat dan mengawal diabetes anda. Dengan ini, kami memberikan ganjaran`,
    glucoseStrips: 'strip ujian kepada anda.',
    myRewards: 'Ganjaran Saya',
    myRewardsLink: 'GANJARAN SAYA',
    completed: 'Selesai',
    trackDelivery: 'Jejak Penghantaran',
    cancelDelivery: 'Batal Penghantaran',
    setOfGlucometerWithStrips: 'Set Glucometer dengan jalur',
    oneGlucometer: '1 Glucometer & 1 kotak ujian strip​',
    mealPlan: 'Pelan Pemakanan',
    mealPlanPath: 'Pelan Pemakanan PATh',
    sportTowel: '1 tali regangan & 1 tuala sukan',
    glucernaWithStrips: '2 Glucerna 400g & 1 kotak ujian strip',
    download: 'Muat turun',
    notCompleted: 'Belum Selesai',
    goToCourse: 'Pergi ke kursus',
    attendNow: 'Hadiri Sekarang',
    doYouWantToCancel:
      'Adakah anda ingin membatalkan pesanan untuk bahan sokongan?',
    ifYouChangeYourMind:
      'Jika anda berubah fikiran dan ingin menebus semula bahan-bahan tersebut, sila hubungi:',
    deliveryDetails: 'Maklumat Penghantaran',
    deliveryStatus: 'Status penghantaran',
    toReceive: 'Untuk Diterima',
    shippingInformation: 'Maklumat penghantaran',
    deliveryId: 'ID Penghantaran:',
    item: 'Item',
    glucometerWithStrips: 'Glucometer with Strips',
    deliveryAddress: 'Alamat penghantaran',
    trackYourOrder: 'Jejak pesanan anda',
    orderReceived: 'Pesanan Diterima',
    weReceivedYourOrder: 'Kami telah menerima pesanan anda pada',
    orderProcessing: 'Pesanan anda sedang diproseskan',
    weArePreparingYourOrder: 'Kami sedang menyediakan pesanan anda',
    orderShipped: 'Pesanan dihantar',
    trackingCode: 'Kod Jejak:',
    checkYourDeliveryStatus: 'Semak status penghantaran anda di',
    here: 'sini',
    myAddress: 'Alamat Saya',
    myAddressLink: 'ALAMAT SAYA',
    contact: 'Kenalan',
    address: 'Alamat',
    saveAddress: 'Simpan Alamat',
    module: 'Modul',
    viewMealPlan: 'Lihat Rancangan Makanan',
    received: 'Menerima',
  },
};
